import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { MenuItem, FormControl, InputLabel, TextField, Button, Container, Typography, Grid, Select, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/system';

//require('dotenv').config();

// Styled components for file input and button
const StyledFileInput = styled('input')(({ theme }) => ({
  display: 'none',
}));

const StyledButton = styled(Button)(({ theme }) => ({
  width: '100%',
  height: '56px',
  borderRadius: '4px',
  border: `1px solid ${theme?.palette?.divider || '#ddd'}`, // Safe access theme or fallback
  justifyContent: 'flex-start',
  padding: '0 14px',
  textTransform: 'none',
  color: theme?.palette?.text?.primary || '#000', // Safe access or fallback to black
  backgroundColor: theme?.palette?.background?.paper || '#fff', // Fallback to white
  '&:hover': {
    backgroundColor: theme?.palette?.action?.hover || '#f5f5f5',
  },
}));

const AddBookmark = () => {
  const navigate = useNavigate();

const baseUrl = process.env.REACT_APP_BASE_URL;


  // State for all input fields
  const [ofirstName, setOfirstName] = useState('');
  const [omiddleName, setOmiddleName] = useState('');
  const [olastName, setOlastName] = useState('');
  const [ocontact, setOcontact] = useState('');
  const [odistrict, setOdistrict] = useState('');
  const [oemgContact, setOemgContact] = useState('');
  const [oaddress, setOaddress] = useState('');
  const [vbusinessName, setVbusinessName] = useState('');
  const [vreceiptNo, setVreceiptNo] = useState('');
  const [vvinNo, setVvinNo] = useState('');
  const [vengineNo, setVengineNo] = useState('');
  const [vkNo, setVkNo] = useState('');
  const [vlicensePlate, setVlicensePlate] = useState('');
  const [vvehicleID, setVvehicleID] = useState('');
  const [vcolor, setVcolor] = useState('');
  const [vAddress, setVAddress] = useState('');
  const [opfirstName, setOpfirstName] = useState('');
  const [opmiddleName, setOpmiddleName] = useState('');
  const [oplastName, setOplastName] = useState('');
  const [opContact, setOpContact] = useState('');
  const [opemgContact, setOpemgContact] = useState('');
  const [opdriverLicense, setOpdriverLicense] = useState('');
  const [opAddress, setOpAddress] = useState('');

  // States for profile images
  const [ownerProfileImage, setOwnerProfileImage] = useState(null);
  const [operatorProfileImage, setOperatorProfileImage] = useState(null);

  // States for file names
  const [ownerProfileImageName, setOwnerProfileImageName] = useState('');
  const [operatorProfileImageName, setOperatorProfileImageName] = useState('');

  // State for users dropdown
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState('');

  // Fetch users from the backend
  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios.get(`${baseUrl}/auth/users`);
        setUsers(response.data);
      } catch (error) {
        console.error('Error fetching users', error);
      }
    };

    fetchUsers();
  }, []);

  // Handle form submission
const handleBookmark = async (e) => {
  e.preventDefault();
  
  const formData = new FormData();

  formData.append('ofirstName', ofirstName);
  formData.append('omiddleName', omiddleName);
  formData.append('olastName', olastName);
  formData.append('ocontact', ocontact);
  formData.append('odistrict', odistrict);
  formData.append('oemgContact', oemgContact);
  formData.append('oaddress', oaddress);
  formData.append('vbusinessName', vbusinessName);
  formData.append('vreceiptNo', vreceiptNo);
  formData.append('vvinNo', vvinNo);
  formData.append('vengineNo', vengineNo);
  formData.append('vkNo', vkNo);
  formData.append('vlicensePlate', vlicensePlate);
  formData.append('vvehicleID', vvehicleID);
  formData.append('vcolor', vcolor);
  formData.append('vAddress', vAddress);
  formData.append('opfirstName', opfirstName);
  formData.append('opmiddleName', opmiddleName);
  formData.append('oplastName', oplastName);
  formData.append('opContact', opContact);
  formData.append('opemgContact', opemgContact);
  formData.append('opdriverLicense', opdriverLicense);
  formData.append('opAddress', opAddress);
  formData.append('userId', selectedUser);

  if (ownerProfileImage) formData.append('ownerProfileImage', ownerProfileImage);
  if (operatorProfileImage) formData.append('operatorProfileImage', operatorProfileImage);

  const token = localStorage.getItem('token'); // Retrieve the token from local storage

  try {
    await axios.post(`${baseUrl}/auth/addBookmark`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${token}` // Include the token in the headers
      }
    });
    alert('Bookmark Added!');
    navigate('/dashboard/bookmarks');
  } catch (error) {
    console.error('Error adding bookmark', error);
    alert('Bookmark Not Added!');
  }
};

const handleOwnerImageChange = (e) => {
  setOwnerProfileImage(e.target.files[0]);
  setOwnerProfileImageName(e.target.files[0].name);
};

const handleOperatorImageChange = (e) => {
  setOperatorProfileImage(e.target.files[0]);
  setOperatorProfileImageName(e.target.files[0].name);
};

  return (
    <Container maxWidth="xl" sx={{ mb: 3 }}>
      <Typography variant="h4" component="h1" sx={{ mt: 3, mb: 3 }} gutterBottom>
        Add Bookmark
      </Typography>
      <form onSubmit={handleBookmark}>
        {/* Owner's Information */}
        <Box sx={{ mb: 3, p: 2, border: '1px solid', borderColor: 'divider', borderRadius: 1 }}>
          <Typography variant="h6">Owner's Information</Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <TextField
                fullWidth
                margin="normal"
                label="Owner First Name"
                variant="outlined"
                value={ofirstName}
                onChange={(e) => setOfirstName(e.target.value)}
                required
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                fullWidth
                margin="normal"
                label="Owner Middle Name"
                variant="outlined"
                value={omiddleName}
                onChange={(e) => setOmiddleName(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                fullWidth
                margin="normal"
                label="Owner Last Name"
                variant="outlined"
                value={olastName}
                onChange={(e) => setOlastName(e.target.value)}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                margin="normal"
                label="Owner Contact"
                variant="outlined"
                value={ocontact}
                onChange={(e) => setOcontact(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                margin="normal"
                label="Owner District"
                variant="outlined"
                value={odistrict}
                onChange={(e) => setOdistrict(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                margin="normal"
                label="Owner Emergency Contact"
                variant="outlined"
                value={oemgContact}
                onChange={(e) => setOemgContact(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                margin="normal"
                label="Owner Address"
                variant="outlined"
                value={oaddress}
                onChange={(e) => setOaddress(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <StyledButton variant="outlined" component="label">
                Upload Owner Profile Image
                <StyledFileInput
                  type="file"
                  accept="image/*"
                  onChange={handleOwnerImageChange}
                />
              </StyledButton>
              {ownerProfileImageName && (
                <Typography variant="body2" sx={{ mt: 1 }}>
                  {ownerProfileImageName}
                </Typography>
              )}
            </Grid>
          </Grid>
        </Box>

        {/* Vehicle Information */}
        <Box sx={{ mb: 3, p: 2, border: '1px solid', borderColor: 'divider', borderRadius: 1 }}>
          <Typography variant="h6">Vehicle Information</Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <TextField
                fullWidth
                margin="normal"
                label="Business Name"
                variant="outlined"
                value={vbusinessName}
                onChange={(e) => setVbusinessName(e.target.value)}
                required
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                fullWidth
                margin="normal"
                label="Receipt Number"
                variant="outlined"
                value={vreceiptNo}
                onChange={(e) => setVreceiptNo(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                fullWidth
                margin="normal"
                label="VIN Number"
                variant="outlined"
                value={vvinNo}
                onChange={(e) => setVvinNo(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                fullWidth
                margin="normal"
                label="Engine Number"
                variant="outlined"
                value={vengineNo}
                onChange={(e) => setVengineNo(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                fullWidth
                margin="normal"
                label="K Number"
                variant="outlined"
                value={vkNo}
                onChange={(e) => setVkNo(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                fullWidth
                margin="normal"
                label="License Plate"
                variant="outlined"
                value={vlicensePlate}
                onChange={(e) => setVlicensePlate(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                fullWidth
                margin="normal"
                label="Vehicle ID"
                variant="outlined"
                value={vvehicleID}
                onChange={(e) => setVvehicleID(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                fullWidth
                margin="normal"
                label="Color"
                variant="outlined"
                value={vcolor}
                onChange={(e) => setVcolor(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                fullWidth
                margin="normal"
                label="Address"
                variant="outlined"
                value={vAddress}
                onChange={(e) => setVAddress(e.target.value)}
              />
            </Grid>
          </Grid>
        </Box>

        {/* Operator's Information */}
        <Box sx={{ mb: 3, p: 2, border: '1px solid', borderColor: 'divider', borderRadius: 1 }}>
          <Typography variant="h6">Operator's Information</Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <TextField
                fullWidth
                margin="normal"
                label="Operator First Name"
                variant="outlined"
                value={opfirstName}
                onChange={(e) => setOpfirstName(e.target.value)}
                required
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                fullWidth
                margin="normal"
                label="Operator Middle Name"
                variant="outlined"
                value={opmiddleName}
                onChange={(e) => setOpmiddleName(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                fullWidth
                margin="normal"
                label="Operator Last Name"
                variant="outlined"
                value={oplastName}
                onChange={(e) => setOplastName(e.target.value)}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                margin="normal"
                label="Operator Contact"
                variant="outlined"
                value={opContact}
                onChange={(e) => setOpContact(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                margin="normal"
                label="Operator Emergency Contact"
                variant="outlined"
                value={opemgContact}
                onChange={(e) => setOpemgContact(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                margin="normal"
                label="Operator Driver License"
                variant="outlined"
                value={opdriverLicense}
                onChange={(e) => setOpdriverLicense(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                margin="normal"
                label="Operator Address"
                variant="outlined"
                value={opAddress}
                onChange={(e) => setOpAddress(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <StyledButton variant="outlined" component="label">
                Upload Operator Profile Image
                <StyledFileInput
                  type="file"
                  accept="image/*"
                  onChange={handleOperatorImageChange}
                />
              </StyledButton>
              {operatorProfileImageName && (
                <Typography variant="body2" sx={{ mt: 1 }}>
                  {operatorProfileImageName}
                </Typography>
              )}
            </Grid>
          </Grid>
        </Box>

        {/* User Selection */}
        <Box sx={{ mb: 3 }}>
          <FormControl fullWidth>
            <InputLabel id="user-select-label">Select User</InputLabel>
            <Select
              labelId="user-select-label"
              value={selectedUser}
              onChange={(e) => setSelectedUser(e.target.value)}
              label="Select User"
            >
              {users.map((user) => (
                <MenuItem key={user.id} value={user.id}>
                  {user.username} 
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        <Box item xs={12} sx={{ mt: 3 }}>
        <Button type="submit" variant="contained" color="primary" fullWidth>
          Add Bookmark
        </Button>
        </Box>
      </form>
    </Container>
  );
};

export default AddBookmark;

