import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Button, Container, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton, TextField, InputAdornment, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import SearchIcon from '@mui/icons-material/Search';
import DownloadIcon from '@mui/icons-material/Download';
import AssignmentIcon from '@mui/icons-material/Assignment';
import { CSVLink } from 'react-csv';

//require('dotenv').config();

const BookmarkList = () => {
  const [bookmarks, setBookmarks] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedBookmarkId, setSelectedBookmarkId] = useState(null);
  const navigate = useNavigate();

const baseUrl = process.env.REACT_APP_BASE_URL;


useEffect(() => {
  const source = axios.CancelToken.source();

  // Replace `yourToken` with the actual token retrieval method
  const token = localStorage.getItem('token'); // Example: Retrieve token from localStorage or state

  // Fetch bookmarks from the API with token
  axios.get(`${baseUrl}/auth/bookmarks`, {
    headers: {
      'Authorization': `Bearer ${token}`
    },
    cancelToken: source.token
  })
  .then(response => {
    console.log('Fetched Bookmarks:', response.data);
    setBookmarks(response.data);
  })
  .catch(error => {
    if (!axios.isCancel(error)) {
      console.error('Error fetching bookmarks:', error);
    }
  });

  return () => {
    source.cancel('Component unmounted. Request canceled.');
  };
}, []);

  const handleAdd = () => {
    navigate('/dashboard/addBookmark');
  };

  const handleEdit = (bookmarkId) => {
    navigate(`/dashboard/editBookmark/${bookmarkId}`);
  };

  const handleProfile = (bookmarkId) => {
    navigate(`/dashboard/bookmarkProfiles/${bookmarkId}`);
  }

  const handleOpenDialog = (bookmarkId) => {
    setSelectedBookmarkId(bookmarkId);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedBookmarkId(null);
  };

const handleDelete = () => {
  const token = localStorage.getItem('token'); // Retrieve the token from local storage

  axios.delete(`${baseUrl}/auth/bookmarks/${selectedBookmarkId}`, {
    headers: { Authorization: `Bearer ${token}` } // Include the token in the headers
  })
    .then(() => {
      // Remove deleted bookmark from state
      setBookmarks(bookmarks.filter(bookmark => bookmark.id !== selectedBookmarkId));
      handleCloseDialog();
    })
    .catch(error => {
      console.error('Error deleting bookmark:', error);
      // Optionally show an error message to the user
    });
};

  const filteredBookmarks = bookmarks.filter((bookmark) =>
    bookmark.username?.toLowerCase().includes(searchTerm.toLowerCase()) ||
    bookmark.ofirstName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
    bookmark.omiddleName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
    bookmark.olastName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
    bookmark.ocontact?.toLowerCase().includes(searchTerm.toLowerCase()) ||
    bookmark.odistrict?.toLowerCase().includes(searchTerm.toLowerCase()) ||
    bookmark.oemgContact?.toLowerCase().includes(searchTerm.toLowerCase()) ||
    bookmark.oaddress?.toLowerCase().includes(searchTerm.toLowerCase()) ||
    bookmark.vbusinessName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
    bookmark.vreceiptNo?.toLowerCase().includes(searchTerm.toLowerCase()) ||
    bookmark.vvinNo?.toLowerCase().includes(searchTerm.toLowerCase()) ||
    bookmark.vengineNo?.toLowerCase().includes(searchTerm.toLowerCase()) ||
    bookmark.vkNo?.toLowerCase().includes(searchTerm.toLowerCase()) ||
    bookmark.vlicensePlate?.toLowerCase().includes(searchTerm.toLowerCase()) ||
    bookmark.vvehicleID?.toLowerCase().includes(searchTerm.toLowerCase()) ||
    bookmark.vcolor?.toLowerCase().includes(searchTerm.toLowerCase()) ||
    bookmark.vAddress?.toLowerCase().includes(searchTerm.toLowerCase()) ||
    bookmark.opfirstName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
    bookmark.opmiddleName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
    bookmark.oplastName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
    bookmark.opContact?.toLowerCase().includes(searchTerm.toLowerCase()) ||
    bookmark.opemgContact?.toLowerCase().includes(searchTerm.toLowerCase()) ||
    bookmark.opdriverLicense?.toLowerCase().includes(searchTerm.toLowerCase()) ||
    bookmark.opAddress?.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const headers = [
    { label: 'User ID', key: 'userId' },
    { label: 'Owner First Name', key: 'ofirstName' },
    { label: 'Owner Middle Name', key: 'omiddleName' },
    { label: 'Owner Last Name', key: 'olastName' },
    { label: 'Owner Contact', key: 'ocontact' },
    { label: 'Owner District', key: 'odistrict' },
    { label: 'Owner Emergency Contact', key: 'oemgContact' },
    { label: 'Owner Address', key: 'oaddress' },
    { label: 'Vehicle Business Name', key: 'vbusinessName' },
    { label: 'Vehicle Receipt No', key: 'vreceiptNo' },
    { label: 'Vehicle VIN No', key: 'vvinNo' },
    { label: 'Vehicle Engine No', key: 'vengineNo' },
    { label: 'Vehicle K No', key: 'vkNo' },
    { label: 'Vehicle License Plate', key: 'vlicensePlate' },
    { label: 'Vehicle Vehicle ID', key: 'vvehicleID' },
    { label: 'Vehicle Color', key: 'vcolor' },
    { label: 'Vehicle Address', key: 'vAddress' },
    { label: 'Operator First Name', key: 'opfirstName' },
    { label: 'Operator Middle Name', key: 'opmiddleName' },
    { label: 'Operator Last Name', key: 'oplastName' },
    { label: 'Operator Contact', key: 'opContact' },
    { label: 'Operator Emergency Contact', key: 'opemgContact' },
    { label: 'Operator Driver License', key: 'opdriverLicense' },
    { label: 'Operator Address', key: 'opAddress' }
  ];

  return (
    <Container maxWidth="lg">
      <Typography variant="h4" component="h1" sx={{ mb: 3, mt: 3 }} gutterBottom>
        Bookmarks
      </Typography>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '16px' }}>
        <TextField
          label="Search Bookmarks"
          variant="outlined"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
        <div>
          <Button
            variant="contained"
            color="primary"
            onClick={handleAdd}
            style={{ marginRight: '16px' }}
          >
            Add Bookmark
          </Button>
          <CSVLink data={filteredBookmarks} headers={headers} filename="bookmarks.csv" style={{ textDecoration: 'none' }}>
            <Button
              variant="contained"
              color="secondary"
              startIcon={<DownloadIcon />}
            >
              Download CSV
            </Button>
          </CSVLink>
        </div>
      </div>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>User ID</TableCell>
              <TableCell>Owner First Name</TableCell>
              <TableCell>Owner Middle Name</TableCell>
              <TableCell>Owner Last Name</TableCell>
              <TableCell>Owner Contact</TableCell>
              <TableCell>Owner District</TableCell>
              <TableCell>Owner Emergency Contact</TableCell>
              <TableCell>Owner Address</TableCell>
              <TableCell>Vehicle Business Name</TableCell>
              <TableCell>Vehicle Receipt No</TableCell>
              <TableCell>Vehicle VIN No</TableCell>
              <TableCell>Vehicle Engine No</TableCell>
              <TableCell>Vehicle K No</TableCell>
              <TableCell>Vehicle License Plate</TableCell>
              <TableCell>Vehicle Vehicle ID</TableCell>
              <TableCell>Vehicle Color</TableCell>
              <TableCell>Vehicle Address</TableCell>
              <TableCell>Operator First Name</TableCell>
              <TableCell>Operator Middle Name</TableCell>
              <TableCell>Operator Last Name</TableCell>
              <TableCell>Operator Contact</TableCell>
              <TableCell>Operator Emergency Contact</TableCell>
              <TableCell>Operator Driver License</TableCell>
              <TableCell>Operator Address</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredBookmarks.map((bookmark) => (
              <TableRow key={bookmark.id}>
                <TableCell>{bookmark.userId}</TableCell>
                <TableCell>{bookmark.ofirstName}</TableCell>
                <TableCell>{bookmark.omiddleName}</TableCell>
                <TableCell>{bookmark.olastName}</TableCell>
                <TableCell>{bookmark.ocontact}</TableCell>
                <TableCell>{bookmark.odistrict}</TableCell>
                <TableCell>{bookmark.oemgContact}</TableCell>
                <TableCell>{bookmark.oaddress}</TableCell>
                <TableCell>{bookmark.vbusinessName}</TableCell>
                <TableCell>{bookmark.vreceiptNo}</TableCell>
                <TableCell>{bookmark.vvinNo}</TableCell>
                <TableCell>{bookmark.vengineNo}</TableCell>
                <TableCell>{bookmark.vkNo}</TableCell>
                <TableCell>{bookmark.vlicensePlate}</TableCell>
                <TableCell>{bookmark.vvehicleID}</TableCell>
                <TableCell>{bookmark.vcolor}</TableCell>
                <TableCell>{bookmark.vAddress}</TableCell>
                <TableCell>{bookmark.opfirstName}</TableCell>
                <TableCell>{bookmark.opmiddleName}</TableCell>
                <TableCell>{bookmark.oplastName}</TableCell>
                <TableCell>{bookmark.opContact}</TableCell>
                <TableCell>{bookmark.opemgContact}</TableCell>
                <TableCell>{bookmark.opdriverLicense}</TableCell>
                <TableCell>{bookmark.opAddress}</TableCell>
                <TableCell>
                  <IconButton color="primary" onClick={() => handleEdit(bookmark.id)}>
                    <EditIcon />
                  </IconButton>
                  <IconButton color="primary" onClick={() => handleProfile(bookmark.id)}>
                    <AssignmentIcon />
                  </IconButton>
                  <IconButton color="secondary" onClick={() => handleOpenDialog(bookmark.id)}>
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {/* Confirmation Dialog */}
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you want to delete this bookmark?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDelete} color="secondary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default BookmarkList;

