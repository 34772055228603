import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { MenuItem, FormControl, InputLabel, TextField, Button, Container, Typography, Grid, Select, Box } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';

//require('dotenv').config();

const EditBookmark = () => {
  const navigate = useNavigate();
  const { id } = useParams(); // Get the bookmark ID from the URL

  const baseUrl = process.env.REACT_APP_BASE_URL;


  // State for all input fields
  const [ofirstName, setOfirstName] = useState('');
  const [omiddleName, setOmiddleName] = useState('');
  const [olastName, setOlastName] = useState('');
  const [ocontact, setOcontact] = useState('');
  const [odistrict, setOdistrict] = useState('');
  const [oemgContact, setOemgContact] = useState('');
  const [oaddress, setOaddress] = useState('');
  const [vbusinessName, setVbusinessName] = useState('');
  const [vreceiptNo, setVreceiptNo] = useState('');
  const [vvinNo, setVvinNo] = useState('');
  const [vengineNo, setVengineNo] = useState('');
  const [vkNo, setVkNo] = useState('');
  const [vlicensePlate, setVlicensePlate] = useState('');
  const [vvehicleID, setVvehicleID] = useState('');
  const [vcolor, setVcolor] = useState('');
  const [vAddress, setVAddress] = useState('');
  const [opfirstName, setOpfirstName] = useState('');
  const [opmiddleName, setOpmiddleName] = useState('');
  const [oplastName, setOplastName] = useState('');
  const [opContact, setOpContact] = useState('');
  const [opemgContact, setOpemgContact] = useState('');
  const [opdriverLicense, setOpdriverLicense] = useState('');
  const [opAddress, setOpAddress] = useState('');
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState('');
  const [loading, setLoading] = useState(true);

  // Fetch bookmark and users

useEffect(() => {
  const fetchData = async () => {
    try {
      const token = localStorage.getItem('token');

      const [bookmarkResponse, usersResponse] = await Promise.all([
        axios.get(`${baseUrl}/auth/bookmarks/${id}`, {
          headers: { Authorization: `Bearer ${token}` }
        }),
        axios.get(`${baseUrl}/auth/users`, {
          headers: { Authorization: `Bearer ${token}` }
        })
      ]);

      const bookmark = bookmarkResponse.data;
      setOfirstName(bookmark.ofirstName);
      setOmiddleName(bookmark.omiddleName);
      setOlastName(bookmark.olastName);
      setOcontact(bookmark.ocontact);
      setOdistrict(bookmark.odistrict);
      setOemgContact(bookmark.oemgContact);
      setOaddress(bookmark.oaddress);
      setVbusinessName(bookmark.vbusinessName);
      setVreceiptNo(bookmark.vreceiptNo);
      setVvinNo(bookmark.vvinNo);
      setVengineNo(bookmark.vengineNo);
      setVkNo(bookmark.vkNo);
      setVlicensePlate(bookmark.vlicensePlate);
      setVvehicleID(bookmark.vvehicleID);
      setVcolor(bookmark.vcolor);
      setVAddress(bookmark.vAddress);
      setOpfirstName(bookmark.opfirstName);
      setOpmiddleName(bookmark.opmiddleName);
      setOplastName(bookmark.oplastName);
      setOpContact(bookmark.opContact);
      setOpemgContact(bookmark.opemgContact);
      setOpdriverLicense(bookmark.opdriverLicense);
      setOpAddress(bookmark.opAddress);
      setSelectedUser(bookmark.userId);
      setUsers(usersResponse.data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching data', error);
      setLoading(false);
    }
  };

  fetchData();
}, [id]);



  // Handle form submission
const handleUpdateBookmark = async (e) => {
  e.preventDefault();
  try {
    const token = localStorage.getItem('token'); // Get the token from local storage

    await axios.put(`${baseUrl}/auth/updateBookmark/${id}`, {
      ofirstName, omiddleName, olastName, ocontact, odistrict, oemgContact, oaddress,
      vbusinessName, vreceiptNo, vvinNo, vengineNo, vkNo, vlicensePlate, vvehicleID, vcolor, vAddress,
      opfirstName, opmiddleName, oplastName, opContact, opemgContact, opdriverLicense, opAddress,
      userId: selectedUser // Linking the bookmark to the selected user
    }, {
      headers: { Authorization: `Bearer ${token}` } // Include the token in the headers
    });

    alert('Bookmark Updated!');
    navigate('/dashboard/bookmarks');
  } catch (error) {
    alert('Bookmark Not Updated!');
    console.error('Error updating bookmark:', error);
  }
};

  if (loading) return <Typography>Loading...</Typography>;

  return (
    <Container maxWidth="xl" sx={{ mb: 3 }}>
      <Typography variant="h4" component="h1" sx={{ mt: 3, mb: 3 }} gutterBottom>
        Edit Bookmark
      </Typography>
      <form onSubmit={handleUpdateBookmark}>
        {/* Owner's Information */}
        <Box sx={{ mb: 3, p: 2, border: '1px solid', borderColor: 'divider', borderRadius: 1 }}>
          <Typography variant="h6">Owner's Information</Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <TextField
                fullWidth
                margin="normal"
                label="Owner First Name"
                variant="outlined"
                value={ofirstName}
                onChange={(e) => setOfirstName(e.target.value)}
                required
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                fullWidth
                margin="normal"
                label="Owner Middle Name"
                variant="outlined"
                value={omiddleName}
                onChange={(e) => setOmiddleName(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                fullWidth
                margin="normal"
                label="Owner Last Name"
                variant="outlined"
                value={olastName}
                onChange={(e) => setOlastName(e.target.value)}
                required
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                fullWidth
                margin="normal"
                label="Owner Contact"
                variant="outlined"
                value={ocontact}
                onChange={(e) => setOcontact(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                fullWidth
                margin="normal"
                label="Owner District"
                variant="outlined"
                value={odistrict}
                onChange={(e) => setOdistrict(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                fullWidth
                margin="normal"
                label="Owner Emergency Contact"
                variant="outlined"
                value={oemgContact}
                onChange={(e) => setOemgContact(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                fullWidth
                margin="normal"
                label="Owner Address"
                variant="outlined"
                value={oaddress}
                onChange={(e) => setOaddress(e.target.value)}
              />
            </Grid>
          </Grid>
        </Box>

        {/* Vehicle Information */}
        <Box sx={{ mb: 3, p: 2, border: '1px solid', borderColor: 'divider', borderRadius: 1 }}>
          <Typography variant="h6">Vehicle Information</Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <TextField
                fullWidth
                margin="normal"
                label="Business Name"
                variant="outlined"
                value={vbusinessName}
                onChange={(e) => setVbusinessName(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                fullWidth
                margin="normal"
                label="Receipt Number"
                variant="outlined"
                value={vreceiptNo}
                onChange={(e) => setVreceiptNo(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                fullWidth
                margin="normal"
                label="VIN Number"
                variant="outlined"
                value={vvinNo}
                onChange={(e) => setVvinNo(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                fullWidth
                margin="normal"
                label="Engine Number"
                variant="outlined"
                value={vengineNo}
                onChange={(e) => setVengineNo(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                fullWidth
                margin="normal"
                label="Key Number"
                variant="outlined"
                value={vkNo}
                onChange={(e) => setVkNo(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                fullWidth
                margin="normal"
                label="License Plate"
                variant="outlined"
                value={vlicensePlate}
                onChange={(e) => setVlicensePlate(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                fullWidth
                margin="normal"
                label="Vehicle ID"
                variant="outlined"
                value={vvehicleID}
                onChange={(e) => setVvehicleID(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                fullWidth
                margin="normal"
                label="Color"
                variant="outlined"
                value={vcolor}
                onChange={(e) => setVcolor(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                fullWidth
                margin="normal"
                label="Address"
                variant="outlined"
                value={vAddress}
                onChange={(e) => setVAddress(e.target.value)}
              />
            </Grid>
          </Grid>
        </Box>

        {/* Operator Information */}
        <Box sx={{ mb: 3, p: 2, border: '1px solid', borderColor: 'divider', borderRadius: 1 }}>
          <Typography variant="h6">Operator Information</Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <TextField
                fullWidth
                margin="normal"
                label="Operator First Name"
                variant="outlined"
                value={opfirstName}
                onChange={(e) => setOpfirstName(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                fullWidth
                margin="normal"
                label="Operator Middle Name"
                variant="outlined"
                value={opmiddleName}
                onChange={(e) => setOpmiddleName(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                fullWidth
                margin="normal"
                label="Operator Last Name"
                variant="outlined"
                value={oplastName}
                onChange={(e) => setOplastName(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                fullWidth
                margin="normal"
                label="Operator Contact"
                variant="outlined"
                value={opContact}
                onChange={(e) => setOpContact(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                fullWidth
                margin="normal"
                label="Operator Emergency Contact"
                variant="outlined"
                value={opemgContact}
                onChange={(e) => setOpemgContact(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                fullWidth
                margin="normal"
                label="Operator Driver License"
                variant="outlined"
                value={opdriverLicense}
                onChange={(e) => setOpdriverLicense(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                fullWidth
                margin="normal"
                label="Operator Address"
                variant="outlined"
                value={opAddress}
                onChange={(e) => setOpAddress(e.target.value)}
              />
            </Grid>
          </Grid>
        </Box>

        {/* User Selection */}
        <Box sx={{ mb: 3 }}>
          <Typography variant="h6">Linking</Typography>

          <FormControl fullWidth>
            <InputLabel>User</InputLabel>
            <Select
              value={selectedUser}
              onChange={(e) => setSelectedUser(e.target.value)}
              label="User"
            >
              {users.map((user) => (
                <MenuItem key={user.id} value={user.id}>
                  {user.username}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>

        {/* Submit Button */}
        <Box xs={12} sx={{ mt: 3, mb: 3 }}>
        <Button type="submit" sx={{ mb: 4, mt: 3 }} variant="contained" color="primary" fullWidth>
          Update Bookmark
        </Button>
        </Box>
      </form>
    </Container>
  );
};

export default EditBookmark;

