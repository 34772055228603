import React, { useState } from 'react';
import { List, ListItem, ListItemText, Drawer, Box, Collapse, IconButton, useMediaQuery } from '@mui/material';
import { ExpandLess, ExpandMore, ChevronLeft, ChevronRight, Dashboard, People, GroupWork, LocalOffer, AddCircle } from '@mui/icons-material';
import AssignmentReturnedIcon from '@mui/icons-material/AssignmentReturned';
import MenuIcon from '@mui/icons-material/Menu';
import { Link } from 'react-router-dom';

const Sidebar = ({ userRole, showSidebar, toggleSidebar }) => {
  const [open, setOpen] = useState({});
  const [collapsed, setCollapsed] = useState(false);
  
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  const handleToggle = (key) => {
    setOpen((prevOpen) => ({
      ...prevOpen,
      [key]: !prevOpen[key],
    }));
  };

  const handleCollapse = () => {
    setCollapsed(!collapsed);
  };

  const drawerWidth = collapsed ? (isMobile ? 60 : 240) : (isMobile ? 240 : 240);

  return (
    <Drawer
      variant={isMobile ? 'temporary' : 'permanent'} // 'temporary' for mobile, 'permanent' for larger screens
      open={showSidebar}
      onClose={toggleSidebar}
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: drawerWidth,
          boxSizing: 'border-box',
          padding: '16px',
          transition: 'width 0.3s',
        },
      }}
    >
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', padding: '0 8px' }}>
        <IconButton onClick={handleCollapse}>
          {collapsed ? <ChevronRight /> : <ChevronLeft />}
        </IconButton>
      </Box>
      <Box sx={{ overflow: 'auto' }}>
        <List>
          <ListItem button onClick={() => handleToggle('dashboard')}>
            <Dashboard sx={{ mr: 2, fontSize: 24 }} />
            <ListItemText primary="Dashboard" />
            {open['dashboard'] ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={open['dashboard']} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItem button component={Link} to="/dashboard/home" sx={{ pl: 4 }}>
                <Dashboard sx={{ mr: 2, fontSize: 24 }} />
                <ListItemText primary="Home" />
              </ListItem>
              <ListItem button component={Link} to="/dashboard/settings" sx={{ pl: 4 }}>
                <Dashboard sx={{ mr: 2, fontSize: 24 }} />
                <ListItemText primary="Settings" />
              </ListItem>
            </List>
          </Collapse>

          {/* User Management */}
          {userRole !== 'Approver' && (
            <>
              <ListItem button onClick={() => handleToggle('userManagement')}>
                <People sx={{ mr: 2, fontSize: 24 }} />
                <ListItemText primary="User Management" />
                {open['userManagement'] ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse in={open['userManagement']} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <ListItem button component={Link} to="/dashboard/register" sx={{ pl: 4 }}>
                    <AddCircle sx={{ mr: 2, fontSize: 24 }} />
                    <ListItemText primary="Register" />
                  </ListItem>
                  <ListItem button component={Link} to="/dashboard/users" sx={{ pl: 4 }}>
                    <People sx={{ mr: 2, fontSize: 24 }} />
                    <ListItemText primary="Users Accounts" />
                  </ListItem>
                </List>
              </Collapse>
            </>
          )}

          {userRole !== 'Approver' && (
            <>
              <ListItem button onClick={() => handleToggle('bookmarkManagement')}>
                <AssignmentReturnedIcon sx={{ mr: 2, fontSize: 24 }} />
                <ListItemText primary="Bookmark Management" />
                {open['bookmarkManagement'] ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse in={open['bookmarkManagement']} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <ListItem button component={Link} to="/dashboard/addBookmark" sx={{ pl: 4 }}>
                    <AddCircle sx={{ mr: 2, fontSize: 24 }} />
                    <ListItemText primary="Add Bookmark" />
                  </ListItem>
                  <ListItem button component={Link} to="/dashboard/bookmarks" sx={{ pl: 4 }}>
                    <LocalOffer sx={{ mr: 2, fontSize: 24 }} />
                    <ListItemText primary="Bookmarks" />
                  </ListItem>
                </List>
              </Collapse>
            </>
          )}
        </List>
      </Box>
    </Drawer>
  );
};

export default Sidebar;
