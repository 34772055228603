
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useAuth } from '../context/auth';
import { TextField, Button, Container, Typography } from '@mui/material';
import { jwtDecode } from 'jwt-decode'; // Ensure you have jwt-decode installed for decoding JWTs

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const { login } = useAuth();
  const baseUrl = process.env.REACT_APP_BASE_URL; // Ensure your environment variable is correctly set up


const handleSubmit = async (event) => {
  event.preventDefault();
  let isMounted = true; // Track if the component is mounted

  try {
    const response = await axios.post(`${baseUrl}/auth/login`, { email, password });

    if (isMounted) {
      const { token, role, bookmark } = response.data; // Expecting token, role, and bookmarkProfile from the backend
      localStorage.setItem('token', token);

      if (token) {
        login(token); // Call the login function from the auth context to update the user state

        // Decode token to extract user information (optional if you need extra details)
        const decoded = jwtDecode(token);
        console.log('Decoded token:', decoded);

        // Redirect based on user role
        if (decoded.role === 'Admin') {
          navigate('/dashboard'); // Admins go to the dashboard
        } else if (decoded.role === 'Approver' && bookmark && bookmark.id) {
          navigate(`/dashboard/bookmarkProfiles/${bookmark.id}`); // Approvers go to their specific BookmarkProfile
        } else if (decoded.role === 'Approver' && !bookmark) {
          console.error("Approver role detected, but no BookmarkProfile was found.");
        } else {
          console.error("Unexpected role or missing BookmarkProfile.");
          // You can navigate to a fallback page or show an error message
        }
      } else {
        console.error("Login Failed: Token not received.");
      }

    }
  } catch (error) {
    if (isMounted) {
      console.error('Login error:', error);
      alert('Invalid email or password');
    }
  }

  return () => {
    isMounted = false; // Cleanup to prevent memory leaks
  };
};



  return (
    <Container maxWidth="xs">
      <Typography variant="h4" sx={{marginTop: 10}} component="h1" gutterBottom>
        Login
      </Typography>
      <form onSubmit={handleSubmit}>
        <TextField
          fullWidth
          margin="normal"
          label="Email"
          variant="outlined"
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <TextField
          fullWidth
          margin="normal"
          label="Password"
          variant="outlined"
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <Button type="submit" variant="contained" color="primary" fullWidth>
          Login
        </Button>
      </form>
    </Container>
  );
};

export default Login;
