
import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Login from './components/Login';
import Register from './components/Register';
import Dashboard from './components/Dashboard';
import Sidebar from './components/Sidebar';
import Topbar from './components/Topbar';
import User from './components/User';
import EditUser from './components/EditUser';
import Home from './components/Home';
import AddBookmark from './components/AddBookmark.js';
import BookmarkList from './components/BookmarkList.js';
import EditBookmark from './components/EditBookmark.js';
import BookmarkProfile from './components/BookmarkProfile.js';
import { AuthProvider, useAuth } from './context/auth';

const App = () => {
  return (
    <AuthProvider>
      <Router>
        <Routes>
          {/* Public routes */}
          <Route path="/" element={<Login />} />
          <Route path="/register" element={<Register />} />
          
          {/* Protected routes */}
          <Route 
            path="/dashboard/*" 
            element={<ProtectedRoute><DashboardLayout /></ProtectedRoute>} 
          />
        </Routes>
      </Router>
    </AuthProvider>
  );
}

// ProtectedRoute logic is directly defined in App.js
const ProtectedRoute = ({ children }) => {
  const { isAuthenticated } = useAuth();

  if (!isAuthenticated) {
    // Redirects to login if not authenticated
    return <Navigate to="/" />;
  }

  return children;
}

// Dashboard layout with sidebar, topbar, and protected routes
const DashboardLayout = () => (
  <div style={{ display: 'flex' }}>
    <Sidebar />
    <div style={{ flex: 1 }}>
      <Topbar />
      <Routes>
        <Route path="dashboard" element={<Dashboard />} />
        <Route path="home" element={<Home />} />
        <Route path="users" element={<User />} />
        <Route path="register" element={<Register />} />
        <Route path="editUsers/:id" element={<EditUser />} />
        <Route path="addBookmark" element={<AddBookmark />} />
        <Route path="bookmarks" element={<BookmarkList />} />
        <Route path="editBookmark/:id" element={<EditBookmark />} />
        <Route path="bookmarkProfiles/:id" element={<BookmarkProfile />} />
        
        {/* Add more routes as needed */}
      </Routes>
    </div>
  </div>
);

export default App;
