
import React, { useState, useEffect } from 'react';
import { Container, Box, Typography, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import axios from 'axios';

const Home = () => {



  return (
    <Container>
      <Typography variant="h4" sx={{ mt: 2 }}>LMTSS</Typography>

        </Container>
  );
};

export default Home;
