
import React, { useState, useEffect } from 'react';
import { AppBar, Toolbar, Typography, Avatar, IconButton, Menu, MenuItem, Box } from '@mui/material';
import axios from 'axios';
import { useAuth } from '../context/auth';
import { useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode'; // Correct the import
import MenuIcon from '@mui/icons-material/Menu'; // Menu icon for toggling sidebar on mobile
import Sidebar from './Sidebar';
import logo from '../assets/Ralph_logo.png';

const Topbar = () => {
  const { isAuthenticated, logout } = useAuth();
  const navigate = useNavigate();

  const [username, setUsername] = useState('');
  const [role, setRole] = useState('');
  const [entity, setEntity] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const [showSidebar, setShowSidebar] = useState(false);

  useEffect(() => {
    const fetchUserInfo = async () => {
      try {
        const token = localStorage.getItem('token');
        if (token) {
          const decodedToken = jwtDecode(token);
          setUsername(decodedToken.username);
          setRole(decodedToken.role);

          const response = await axios.get('http://localhost:3000/auth/user-info', {
            headers: { Authorization: `Bearer ${token}` },
          });
          setEntity(response.data.entity);
        }
      } catch (error) {
        console.error('Error fetching user info:', error);
      }
    };

    fetchUserInfo();
  }, [isAuthenticated]);

  const handleLogout = () => {
    logout();
    navigate('/');
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const toggleSidebar = () => {
    setShowSidebar(!showSidebar);
  };

  return (
    <>
      <AppBar position="static">
        <Toolbar>
          {/* Show the MenuIcon only on mobile */}
          <IconButton edge="start" color="inherit" aria-label="menu" onClick={toggleSidebar}>
            <MenuIcon />
          </IconButton>

                    {/* Logo Box */}
          <Box sx={{ mr: 2 }}>
            <Avatar variant="rounded" src={logo} alt="Logo" sx={{ width: 60, height: 40 }} />
          </Box>

          
          <Box sx={{ flexGrow: 1, p: 3 }}>
            <Typography variant="h6">
              {entity}
            </Typography>
            <Typography variant="body2">
              {role}
            </Typography>
          </Box>
          
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant="body1" sx={{ mr: 1 }}>
              {username}
            </Typography>
            <IconButton onClick={handleMenuOpen}>
              <Avatar alt={username} src="/path/to/avatar.jpg" />
            </IconButton>
            <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
              <MenuItem onClick={handleLogout}>Logout</MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </AppBar>
      
      <Sidebar userRole={role} showSidebar={showSidebar} toggleSidebar={toggleSidebar} />
    </>
  );
};

export default Topbar;
